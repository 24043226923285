<template>
    <div class="container" >
        <div class="container_top">
            <span>公司信息编辑</span>
        </div>
        <div class="container_bottom">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="注册资金" prop="registeredCapital">
                    <el-input v-model="ruleForm.registeredCapital"></el-input>
                </el-form-item>
                <el-form-item label="法人代表" prop="legalRepresentative">
                    <el-input v-model="ruleForm.legalRepresentative"></el-input>
                </el-form-item>
                <el-form-item label="经营状态" prop="businessStatus">
                    <el-input v-model="ruleForm.businessStatus"></el-input>
                </el-form-item>
                <el-form-item label="工商注册号" prop="businessRegistrationNumber">
                    <el-input v-model="ruleForm.businessRegistrationNumber"></el-input>
                </el-form-item>
                <el-form-item label="注册地址" prop="address">
                    <el-input v-model="ruleForm.address"></el-input>
                </el-form-item>
                <el-form-item label="经营范围" prop="businessScope">
                    <el-input v-model="ruleForm.businessScope"></el-input>
                </el-form-item>
                
                <el-form-item>
                    <el-button id="loginin" type="primary" @click="submitForm('ruleForm')">提交</el-button>
                    <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { getCompanyInfo,editCompanyInfo } from '../../requst/api'
export default {
    components: {
        
    },
    data () {
        return {
            ruleForm: {
                registeredCapital: '',
                legalRepresentative:'',
                businessStatus:'',
                businessRegistrationNumber:'',
                address:'',
                businessScope:''
                },
                rules: {
                registeredCapital: [
                    { required: true, message: '不能为空', trigger: 'blur' },
                ],
                legalRepresentative: [
                    { required: true, message: '不能为空', trigger: 'blur' },
                ],
                businessStatus: [
                    { required: true, message: '不能为空', trigger: 'blur' },
                ],
                businessRegistrationNumber: [
                    { required: true, message: '不能为空', trigger: 'blur' },
                ],
                address: [
                    { required: true, message: '不能为空', trigger: 'blur' },
                ],
                businessScope: [
                    { required: true, message: '不能为空', trigger: 'blur' },
                ],
            }

        }
    },
    methods: {
        //修改公司信息
        // "address": "string",
        // "businessRegistrationbusinessRegistrationNumber": "string",
        // "businessScope": "string",
        // "businessStatus": "string",
        // "legalRepresentative": "string",
        // "registeredCapital": "string"
        submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            editCompanyInfo(this.ruleForm).then((res)=>{
                alert('提交成功!');
            })
            // console.log(this.ruleForm)
          } else {
            console.log('提交失败');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    },
    created () {
    },
    created(){
        //获取公司信息
        getCompanyInfo().then((res)=>{
            console.log(res,'这是公司信息')
            this.ruleForm=res.data
        })
    }

}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  .container_top{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    span{
        font-size: 30px;
        font-weight: 600;
        margin-left: 20px;
    }
  }
  .container_bottom{
    width: 100%;
    height: 90%;
    // background: #0f0;
    .el-form {
        display: flex;
        flex-wrap: wrap;
    }
    .el-form-item{
        width: 700px;
    }
  }
}
</style>