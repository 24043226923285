<template>
  <div class="container">
    <span class="container_top" >
      <div class="top_left">
        <span class="spans_k">《互联网药品信息服务资格证书》编号：</span>
        <span class="top_left_span1" @click="go()"
          >本草汇（四川）大数据科技股份有限公司</span
        >
        <span class="add_span"
          >本网站未发布麻醉药品、精神药品、医疗用毒性药品、放射性药品、戒毒药品和医疗机构制剂的产品信息</span
        >
      </div>
      <div class="top_right">
        <span
          :class="index == topTabIndex ? 'active' : ''"
          v-for="(item, index) in topDate"
          :key="index"
          @click="skipTo(index, item.id)"
          >{{ item.name }}</span
        >
      </div>
    </span>
    <div class="container_center" id="home">
      <div class="container_center_box"></div>
    </div>
    <div class="container_center_next" id="about">
      <div class="box1"></div>
      <div class="box2"></div>
    </div>
    <div class="container_center_product" id="product">
      <div class="container_center_product_top">
        <span class="span3">产品展示</span>
      </div>
      <div class="container_center_product_bottom">
        <div class="container_center_product_bottom_top" v-loading="dataFlag">
          <div
            class="container_center_product_bottom_top_box"
            v-for="(item, index) in productData"
            :key="index"
            @click="gotoX(item.id)"
          >
            <div class="box_top">
              <div class="box_top_t">
                <img
                  class="imgs"
                  :src="'data:image/png;base64,' + item.pic"
                  alt=""
                />
              </div>
              <div class="box_top_b">
                <span class="span1">{{ item.name }}</span>
              </div>
            </div>
            <div class="box_bottom">
              <!-- <span class="spans" v-for="(items, indexs) in item.kx" :key="indexs">{{items}}</span> -->
              <span class="spans">剂型：{{ item.dosageForm }}</span>
              <span class="spans">批准文号：{{ item.approvalNumber }}</span>
              <span class="spans">成份:{{ item.component }}</span>
              <span class="spans"
                >适应症或功能主治：{{ item.functionalIndications }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container_center_product_bottom_bottom" id="contact">
      <div class="product_b_t">
        <span>本草汇（四川）大数据科技股份有限公司</span>
      </div>
      <div class="product_b_b">
        <a href="http://beian.miit.gov.cn/ " target="_blank">ICP备案号: 蜀ICP备2024105696</a>
        <span>地址:四川省成都市金牛区营门口路309号2栋1单元2层201号</span>
        <span>电话:028-85153555</span>
        <span>邮箱:luxiaoyi@fengchunmeds.com</span>
        <!-- <span>《互联网药品信息服务资格证书》编号：(川)-非经营性-2024-0102</span> -->
      </div>
    </div>
  </div>
</template>
<script>
import { getDrugPageList } from "../requst/api";
export default {
  components: {},
  data() {
    return {
      dataFlag: true,
      hmoeDataParmes: {
        pageNum: 1,
        pageSize: 3,
      },
      gotoOther: 0,
      navOffsetTop: 0,
      topDate: [
        { name: "首页", id: "#home" },
        { name: "关于我们", id: "#about" },
        { name: "产品展示", id: "#product" },
        { name: "联系我们", id: "#contact" },
      ],
      topTabIndex: 0,
      productData: [],
    };
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.fiexdNav);
    this.getData();
  },
  destroyed() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.fiexdNav);
  },
  methods: {
    //去详情页
    gotoX(id) {
      this.$router.push({
        path: "/datacenter",
        query: {
          id: id,
        },
      });
    },
    //获取首页药品信息
    getHomeData() {
      getDrugPageList(this.hmoeDataParmes).then((res) => {
        this.productData = res.data.data;
        this.dataFlag = false;
      });
    },
    //连续点击去登录界面
    go() {
      if (this.gotoOther >= 5) {
        this.$router.push({ path: "/login" });
      } else {
        this.gotoOther++;
      }
    },
    // tabIndex(index){
    //     this.topTabIndex=index
    // },
    getData() {
      this.navOffsetTop = document.querySelector(".container_top").offsetTop;
    },
    fiexdNav() {
      // 2.获取当前页面的卷曲高度
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const nav = document.querySelector(".container_top");
      // 3.判断卷曲高度是否大于等于导航条的offsetTop值
      if (scrollTop > this.navOffsetTop) {
        //   3.1若满足，则给nav导航添加固定样式
        nav.classList.add("fixedNav");
      } else {
        //   3.2若不满足，则删除nav导航的固定样式
        nav.classList.remove("fixedNav");
      }

      /** 当滚动到一定区域时给导航项添加选中样式 **/
      //  1.获取所有锚点元素
      const contents = document.querySelectorAll(".container>div");
      // 2.获取锚点元素的offsetTop值，并收集在一个数组
      const contentsOffsetTop = [];
      contents.forEach((item) => {
        contentsOffsetTop.push(item.offsetTop);
      });
      // 3.获取页面高度
      const pageHeight = window.innerHeight;
      // 4.获取nav的子元素
      const navChildren = document.querySelectorAll(".top_right span");
      // 5.遍历锚点元素的offsetTop值
      for (let i = 0; i < contentsOffsetTop.length; i++) {
        // 5.1 设置第一项导航默认为选中状态
        if (i === 0) {
          navChildren[0].classList.add("active");
        } else if (scrollTop > contentsOffsetTop[i - 1] + pageHeight / 3) {
          // 排他思想
          for (let j = 0; j < contentsOffsetTop.length; j++) {
            navChildren[j].classList.remove("active");
            navChildren[i].classList.add("active");
          }
        } else {
          navChildren[i].classList.remove("active");
        }
      }
    },
    /**
     *设置点击导航跳转到指定选择器对应的锚点元素
     * @param {*} selector
     **/
    skipTo(index, selector) {
      this.topTabIndex = index;
      const navHeight = document.querySelector(".container_top").offsetHeight;
      // scrollIntoView() js原生方法，实现锚点滚动过渡
      const target = document.querySelector(selector);
      // target.scrollIntoView({ behavior: 'smooth' })
      // scrollTo() 把内容滚动到指定的坐标。减去导航高度的目的：导航用定位固定在顶部，如果不减去，导航栏会遮挡一部分内容
      window.scrollTo(0, target.offsetTop - navHeight);
    },
  },
  created() {
    this.getHomeData();
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  .fixedNav {
    position: fixed;
  }
  .active {
    color: #3235ec;
    font-size: 25px;
  }
  .container_top {
    width: 100%;
    height: 100px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    .top_left {
      width: 60%;
      height: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      -ms-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      .top_left_span1 {
        font-size: 35px;
        color: #3235ec;
      }
      .add_span {
        font-size: 24px;
        font-weight: 600;
      }
      .spans_k {
        width: 100%;
        // height: 30px;\
        font-size: 18px;
        font-weight: 600;
      }
    }
    .top_right {
      width: 40%;
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
      .top_right_span1 {
        color: #3235ec;
        cursor: pointer;
        font-weight: 700;
      }
      .top_right_span2 {
        color: #000;
        cursor: pointer;
      }
    }
  }
  .container_center {
    width: 100%;
    height: 919px;
    background: #f00;
    .container_center_box {
      width: 100%;
      height: 919px;
      background: url("../assets/home1.png");
      background-size: 100% 100%;
    }
  }
  .container_center_next {
    width: 100%;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    .box1 {
      width: 100%;
      height: 919px;
      background: url("../assets/home2.png");
      background-size: 100% 100%;
    }
    .box2 {
      width: 100%;
      height: 919px;
      background: url("../assets/home3.png");
      background-size: 100% 100%;
    }
  }
  .container_center_product {
    width: 100%;
    height: 869px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: column;
    .container_center_product_top {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 35px;
      font-weight: 600;
    }
    .container_center_product_bottom {
      width: 100%;
      height: 769px;
      display: flex;
      flex-direction: column;
      .container_center_product_bottom_top {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .container_center_product_bottom_top_box {
          width: 30%;
          height: 100%;
          display: flex;
          flex-direction: column;
          border: 1px solid #aaa;
          cursor: pointer;
          .box_top {
            width: 100%;
            height: 60%;
            display: flex;
            flex-direction: column;
            .box_top_t {
              width: 100%;
              height: 90%;
              display: flex;
              align-items: center;
              justify-content: center;
              .imgs {
                width: 100%;
                height: 100%;
              }
            }
            .box_top_b {
              width: 100%;
              height: 10%;
              display: flex;
              align-items: center;
              justify-content: center;
              .span1 {
                font-size: 25px;
              }
            }
          }
          .box_bottom {
            width: 100%;
            height: 40%;
            display: flex;
            flex-direction: column;

            .spans {
              font-size: 20px;
              padding: 0 0 15px 15px;
            }
          }
        }
      }
    }
  }
  .container_center_product_bottom_bottom {
    margin-top: 10px;
    width: 100%;
    background: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .product_b_t {
      width: 97%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      span {
        font-size: 30px;
        font-weight: 600;
        color: #fff;
      }
    }
    .product_b_b {
      width: 97%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      justify-content: center;
      align-items: center;
      a{
        font-size: 15px;
        color: #fff;
        margin: 8px 0;
      }
      span {
        font-size: 15px;
        color: #fff;
        margin: 8px 0;
      }
    }
  }
}
</style>
