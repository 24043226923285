import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Cproduct from '../views/Cproduct.vue'
import Cone from '../views/children/Cone.vue'
import Ctwo from '../views/children/Ctwo.vue'
import Ctree from '../views/children/Ctree.vue'
import Cfour from '../views/children/Cfour.vue'
import DataCenter from '../views/DataCenter.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            keepAlive: true // 需要缓存false//不需要缓存
        }

    },
    {
        path: '/datacenter',
        name: 'datacenter',
        component: DataCenter
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/cproduct',
        name: 'cproduct',
        component: Cproduct,
        children: [{
                path: 'cone',
                name: 'cone',
                component: Cone,
            },
            {
                path: 'ctwo',
                name: 'ctwo',
                component: Ctwo,
            },
            {
                path: 'ctree',
                name: 'ctree',
                component: Ctree,
            },
            {
                path: 'cfour',
                name: 'cfour',
                component: Cfour,
            }
        ]
    },
    {
        path: '/homeview',
        name: 'homeview',
        component: HomeView
    }
]

const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes
})

export default router