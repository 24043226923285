<template>
    <div class="container">
    </div>
</template>

<script>
// import { getDeviceData } from '../requst/api'
// import datas from "../util/data"; 
export default {
    data () {
        return {

        }
    },
    methods: {

    },
    created () {

    },
    mounted () {

    }

}
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  background: #ff0;
}
</style>
