<template>
    <div class="container">
        <div class="container_top" >
            <el-button type="primary" @click="ruturnToHome()">返回</el-button>
            <div class="detal">
                <span>产品详情</span>
            </div>
        </div>
        <div class="container_bottom"  v-loading="datesFlag">
            <div class="container_bottom_box" v-if="flag_h">
                <div class="container_bottom_box_left">
                    <img class="imgs" :src="'data:image/png;base64,' + detalData.pic" alt="">
                </div>
                <div class="container_bottom_box_right">
                    <span class="spans" >名称：{{detalData.name}}</span>
                    <span class="spans" >剂型：{{detalData.dosageForm}}</span>
                    <span class="spans" >批准文号：{{detalData.approvalNumber}}</span>
                    <span class="spans" >成份:{{detalData.component}}</span>
                    <span class="spans" >适应症或功能主治：{{detalData.functionalIndications}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {getDrugDetail} from '../requst/api'
export default {
    components: {
    
    },
    data () {
        return {
            detalData:null,
            flag_h:false,
            datesFlag:true
        }
    },
    methods: {
        //返回主页
        ruturnToHome(){
            this.$router.back()
        },
        //获取药品详情数据
        getDrugDetailData(data){
            getDrugDetail(data).then((res)=>{
                this.detalData=res.data
                this.flag_h=true
                this.datesFlag=false
            })
        },

    },
    created () {
        this.getDrugDetailData({id:this.$route.query.id})
    },

}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .container_top{
    width: 100%;
    height: 8%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    .detal{
        width: 40%;
        height: 60%;
        margin-left: 22%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        font-weight: 600;
    }
  }
  .container_bottom{
    width: 100%;
    height: 92%;
    display: flex;
    align-items: center;
    justify-content: center;
    .container_bottom_box{
        width: 80%;
        height: 80%;
        display: flex;
        .container_bottom_box_left{
            width: 35%;
            height: 100%;
            background: #f00;
            border-right: 2px solid #eee;
            .imgs{
                width: 100%;
                height: 100%;
            }
        }
        .container_bottom_box_right{
            width: 65%;
            height: 100%;
            display: flex;
            flex-direction: column;
            .spans{
                font-size: 20px;
                padding:0 0 15px 15px;
            }
            // background: #f0f;
        }
    }
  }
}
</style>