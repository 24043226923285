<template>
    <div class="container">
        <div class="container_top">
            <span>本草汇后台管理</span>
        </div>
        <div class="container_bottom">
            <div class="container_bottom_l">
                <div class="container_bottom_l_tab">
                    <div :class="index==tanbtnIndex?'active':'container_bottom_l_box'" v-for="(item,index) in tabdata" :key="index" @click="tabbtn(index,item.path)">
                        <div class="box_x">
                            <i :class="item.icon"></i>
                            <span>{{item.name}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container_bottom_r">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
// import { getDeviceData } from '../requst/api'
// import datas from "../util/data"; 
export default {
    data () {
        return {
            tabdata:[
                {
                    name:'公司简介',
                    icon:'el-icon-user-solid',
                    path:'cone'
                },
                {
                    name:'产品展示',
                    icon:'el-icon-success',
                    path:'ctwo'
                },
                {
                    name:'关于我们',
                    icon:'el-icon-s-marketing',
                    path:'ctree'
                },
                {
                    name:'联系我们',
                    icon:'el-icon-s-opportunity',
                    path:'cfour'
                }
            ],
            tanbtnIndex:0
        }
    },
    methods: {
        tabbtn(index,path){
            this.tanbtnIndex=index
            this.$router.push({ name:path })

        }
    },
    created () {

    },
    mounted () {
        this.$router.push({ name:'cone' })
    }

}
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
//   background: #ff0;
  display: flex;
  flex-direction: column;
  .container_top{
    width: 100%;
    height: 10%;
    background: #409EFF;
    display: flex;
    align-items: center;
    span{
        margin-left: 30px;
        font-size: 24px;
        font-weight: bold;
        color: #FFFFFF;
    }
  }
  .container_bottom{
    width: 100%;
    height: 90%;
    background: #f0f;
    display: flex;
    align-items: center;
    .container_bottom_l{
        width: 15%;
        height: 100%;
        background: #fff;
        display: flex;
        flex-direction: column;
        .container_bottom_l_tab{
            width: 100%;
            height: 70px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .container_bottom_l_box{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                font-size: 20px;
                padding:10px 0 10px 0;
                cursor: pointer;
                border-right: 4px solid #fff;
            }
            .active{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                font-size: 20px;
                padding:10px 0 10px 0;
                color: #409EFF;
                font-weight: 600;
                cursor: pointer;
                border-right: 4px solid #409EFF;

            }
            .box_x{
                width: 40%;
                height: 50%;
                display: flex;
                align-items: center;
                justify-content: space-between
            }
            
        }
    }
    .container_bottom_r{
        width: 85%;
        height: 100%;
        background: #eee;
    }
  }
}
</style>
