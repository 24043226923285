import request from "./request";


//登录
export function loginIn(data) {
    return request({
        url: '/user/login',
        method: "post",
        data
    });
}
//获取用户信息
//无参数
export function getUserInfo() {
    return request({
        url: '/user/getUser',
        method: "get",
    });
}
//编辑公司信息
//************** */
// params
// {
// "address": "string",
// "businessRegistrationNumber": "string",
// "businessScope": "string",
// "businessStatus": "string",
// "legalRepresentative": "string",
// "registeredCapital": "string"
// }
export function editCompanyInfo(data) {
    return request({
        url: '/companyInfo/editCompanyInfo',
        method: "post",
        data
    });
}
// 获取公司信息
//无参数
export function getCompanyInfo() {
    return request({
        url: '/companyInfo/getCompanyInfo',
        method: "get",
        // data
    });
}


//编辑关于我们
//************** */
// params
// {
// "contactAddress": "string",
// "contactPhone": "string",
// "mail": "string"
// }
export function editAboutInfo(data) {
    return request({
        url: '/aboutInfo/editAboutInfo',
        method: "post",
        data
    });
}

// 获取关于我们
// 无参数
export function getAboutInfo() {
    return request({
        url: '/aboutInfo/getAboutInfo',
        method: "get",
    });
}



//编辑联系我们
//************** */
// params
// {
// "description": "string"
// }
export function editContactInfo(data) {
    return request({
        url: '/contactInfo/editContactInfo',
        method: "post",
        data
    });
}

// 获取联系我们我们
// 无参数
export function getContactInfo() {
    return request({
        url: '/contactInfo/getContactInfo',
        method: "get",
    });
}


//药品



//药品分页
// *************/
// parmes
// {
//     pageNum：..
//     pageSize：...
// }
export function getDrugPageList(params) {
    return request({
        url: '/drug/getDrugPageList',
        method: "get",
        params
    });
}


//添加药品
export function addDrug(data) {
    return request({
        url: '/drug/addDrug',
        method: "post",
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
    });
}
//禁用药品
export function deleteDrug(data) {
    return request({
        url: '/drug/deleteDrug',
        method: "post",
        data
    });
}
//编辑药品
export function editDrug(data) {
    return request({
        url: '/drug/editDrug',
        method: "post",
        data
    });
}
//获取药品详情
export function getDrugDetail(params) {
    return request({
        url: '/drug/getDrugDetail',
        method: "get",
        params
    });
}