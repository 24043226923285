<template>
    <div id="app">
        <!-- <scale-box :width="1920"
                   :height="1080"
                   bgc="transparent"
                   :delay="100"> -->

        <router-view/>
        <!-- </scale-box> -->

    </div>
</template>
<script>

// import ScaleBox from "vue2-scale-box";
export default {
    components: {
        // ScaleBox
    },
    data () {
        return {

        }
    },
    methods: {
        // tabBtn (index) {
        //     this.tabindex = index
        //     localStorage.setItem('_tabindex', index)
        //     if (index == 0) {
        //         this.$router.push({
        //             path: '/'
        //         })
        //     } else {
        //         this.$router.push({
        //             path: '/homeview'
        //         })
        //     }
        // },


    },
    mounted () {

    },
    created () {
    },


}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
  //   background: #f00;
  padding: 0;
  margin: 0;
  //  .el-input{
  //             border-radius: 5px;
  //             background: #f00;

  //           }
}
</style>
