<template>
    <div class="container">
        <div class="container_top">
            <span>关于我们</span>
        </div>
        <div class="container_bottom">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="联系地址" prop="contactAddress">
                    <el-input v-model="ruleForm.contactAddress"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="contactPhone">
                    <el-input v-model="ruleForm.contactPhone"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="mail">
                    <el-input v-model="ruleForm.mail"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button id="loginin" type="primary" @click="submitForm('ruleForm')">提交</el-button>
                    <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { editAboutInfo,getAboutInfo } from '../../requst/api'
export default {
    components: {
        
    },
    data () {
        return {
            ruleForm: {
                contactAddress: '',
                contactPhone:'',
                mail:'',
                },
                rules: {
                contactAddress: [
                    { required: true, message: '不能为空', trigger: 'blur' },
                ],
                contactPhone: [
                    { required: true, message: '不能为空', trigger: 'blur' },
                ],
                mail: [
                    { required: true, message: '不能为空', trigger: 'blur' },
                ],
               
            }

        }
    },
    methods: {
        submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            editAboutInfo(this.ruleForm).then((res)=>{
                console.log(res,'kkkkkkkkkkk')
                alert('提交成功!');
            })
          } else {
            console.log('提交失败!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    },
    created () {
    },
    mounted(){
        //获取关于信息
        getAboutInfo().then((res)=>{
            this.ruleForm=res.data
        })
    }

}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  .container_top{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    span{
        font-size: 30px;
        font-weight: 600;
        margin-left: 20px;
    }
  }
  .container_bottom{
    width: 100%;
    height: 90%;
    // background: #0f0;
    .el-form {
        display: flex;
        flex-wrap: wrap;
    }
    .el-form-item{
        width: 700px;
    }
  }
}
</style>