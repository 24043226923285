<template>
    <div class="container">
        <div class="container_box">
            <div class="container_box_left">
                <div class="container_box_left_box"></div>
            </div>
            <div class="container_box_right">
                <div class="er"></div>
                <div class="container_box_right_box">
                    <div class="container_box_right_box_top">
                        <span class="span1">本草汇后台管理</span>
                    </div>
                    <div class="container_box_right_box_bottom">
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                            <el-form-item label="用户名" prop="userName">
                                <el-input v-model="ruleForm.userName"></el-input>
                            </el-form-item>
                            <el-form-item label="密码" prop="password">
                                <el-input v-model="ruleForm.password" show-password></el-input>
                            </el-form-item>
                            
                            <el-form-item>
                                <el-button id="loginin" type="primary" @click="submitForm('ruleForm')">登录</el-button>
                                <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { loginIn } from '../requst/api'
// import datas from "../util/data"; 
export default {
    data () {
        return {
            ruleForm: {
            userName: '',
            password:'',
        },
        rules: {
          userName: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
            { min: 2, max: 11, message: '长度在  到 11 个字符', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
          ],
        }
        }
    },
    methods: {
        submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            loginIn(this.ruleForm).then((res)=>{
                localStorage.setItem('fc_token',res.data)
                this.$router.push({path:'/cproduct'})
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    },
    created () {

    },
    mounted () {

    }

}
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  background: url('../assets/loging.png');
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .container_box{
    width: 1001.51px;
    height: 541px;
    border-radius: 12px;
    opacity: 1;


    /* 外部/全局/white */
    background: #FFFFFF;

    /* 外部/box-shadow */
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08),0px 2px 6px 0px rgba(0, 0, 0, 0.06),0px 4px 8px 2px rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;
    justify-content: center;
    .container_box_left{
        width: 50%;
        height: 100%;
        // background: #f00;
        display: flex;
        align-items: center;
        justify-content: center;
        .container_box_left_box{
            width: 80%;
            height: 60%;
            background: url('../assets/people.png');
            background-size: 100% 100%;
            border: 2px dashed #dbd7d7;
        }
    }
    .container_box_right{
        position: relative;
        width: 50%;
        height: 100%;
        // background: #f0f;
        display: flex;
        align-items: center;
        justify-content: center;
        .er{
            position: absolute;
            top: 0;
            right: 0;
            width: 94px;
            height: 97px;
            background: url('../assets/er.png');
            background-size: 100% 100%;
            cursor: pointer;
        }
        .container_box_right_box{
            width: 80%;
            height: 60%;
            display: flex;
            flex-direction: column;
            .container_box_right_box_top{
                width: 100%;
                height: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
                .span1{
                    font-size: 35px;
                    font-weight: bold;
                    color: #333333;
                }
            }
            .container_box_right_box_bottom{
                width: 100%;
                height: 80%;
                display: flex;
                align-items: center;
                #loginin{
                    width: 100%;
                }
            }
        }
    }
    }
}
</style>
