<template>
    <div class="container" v-loading="lodingFlag">
        <div class="mask" v-if="maskBoxIndex">
            <div class="mask_box">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="产品名称" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="剂型" prop="dosageForm">
                    <el-input v-model="ruleForm.dosageForm"></el-input>
                </el-form-item>
                <el-form-item label="批准文号" prop="approvalNumber">
                    <el-input v-model="ruleForm.approvalNumber"></el-input>
                </el-form-item>
                <el-form-item label="成分" prop="component">
                    <el-input v-model="ruleForm.component"></el-input>
                </el-form-item>
                <el-form-item label="适应症" prop="indications">
                    <el-input v-model="ruleForm.indications"></el-input>
                </el-form-item>
                <el-form-item label="功能主治" prop="functionalIndications">
                    <el-input v-model="ruleForm.functionalIndications"></el-input>
                </el-form-item>
                
                <el-form-item>
                    <div>缩略图：</div>
                    <input type="file" id="putfile" ref="inputer" @change="fileUpload" />
                    <!-- <el-button @click="upload_" style="margin-right: 2vw">选择文件</el-button>
                    <input v-show="false" ref="fileRef" type="file" @change="fileChange($event)" /> -->
                    <!-- <el-upload
                        class="upload-demo"
                        ref="upload"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :file-list="fileList"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                        </el-upload> -->
                    <el-button id="loginin" type="primary" @click="submitForm('ruleForm')">提交</el-button>
                    <el-button  type="danger" @click="close()">取消</el-button>
                    <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
                </el-form-item>
            </el-form>
            </div>
        </div>
        <div class="container_top">
            <span>产品展示</span>
        </div>
        <div class="container_bottom">
            <div style="margin:20px 0 20px 0">
                <el-button type="primary" @click="add()">新增药品</el-button>
                <!-- <el-button @click="toggleSelection([tableData[1], tableData[2]])">新增药品</el-button> -->
                <el-button type="primary" @click="bianji()">编辑药品</el-button>
                <el-button type="primary" @click="deleteis()">取消药品</el-button>
            </div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                type="selection"
                width="55">
                </el-table-column>
                <el-table-column
                label="ID"
                width="120"
                show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.id }}</template>
                </el-table-column>
                <el-table-column
                prop="name"
                label="产品名称"
                width="120">
                </el-table-column>
                <el-table-column
                prop="dosageForm"
                label="剂型"
                show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                prop="approvalNumber"
                label="批准文号"
                show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                prop="component"
                label="成分"
                show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                prop="indications"
                label="适应症"
                show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                prop="functionalIndications"
                label="功能主治"
                show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                prop="address"
                label="缩略图"
                show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-popover placement="top-start" title="" trigger="hover">
                    <img :src="'data:image/png;base64,' + scope.row.pic" alt="" style="width: 150px;height: 150px">
                    <img slot="reference" :src="'data:image/png;base64,' + scope.row.pic" style="width: 30px;height: 30px">
                    </el-popover>
                    <span>{{scope.row.title}}</span>
                </template>

                </el-table-column>
            </el-table>
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="pageSize"
                layout="total, prev, pager, next"
                :total="total">
                </el-pagination>
        </div>
    </div>
</template>
<script>
import { addDrug ,getDrugPageList,deleteDrug,editDrug} from '../../requst/api'
export default {
    components: {
        
    },
    data () {
        return {
            lodingFlag:false,
            pageSize:null,
            currentPage:1,
            total:null,
            parmesdata:null,
            meidcparmes:{
                pageNum:1,
                pageSize:5
            },
            maskBoxIndex:false,
            //图片上传
            // {name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}
            fileList: [],
            ruleForm: {
                name: '',
                dosageForm:'',
                approvalNumber:'',
                component:'',
                indications:'',
                functionalIndications:'',
                },
                rules: {
                name: [
                    { required: true, message: '不能为空', trigger: 'blur' },
                ],
                dosageForm: [
                    { required: true, message: '不能为空', trigger: 'blur' },
                ],
                approvalNumber: [
                    { required: true, message: '不能为空', trigger: 'blur' },
                ],
                component: [
                    { required: true, message: '不能为空', trigger: 'blur' },
                ],
                indications: [
                    { required: true, message: '不能为空', trigger: 'blur' },
                ],
                functionalIndications: [
                    { required: true, message: '不能为空', trigger: 'blur' },
                ],
            },
            tableData: [
    ],
        multipleSelection: []

        }
    },
    methods: {
    //     handleSizeChange(val) {
    //     console.log(`每页 ${val} 条`);
    //   },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.meidcparmes.pageNum=val
        this.lodingFlag=true
        getDrugPageList(this.meidcparmes).then(res=>{
            this.tableData=res.data.data
            this.lodingFlag=false
        })
    
        
      },
    
        //关闭弹窗
        close(){
            console.log('关闭')
            this.maskBoxIndex=false
            this.fileList=[]
        },
        //图片上传
        upload_() {
 	  //弹出选择本地文件
      this.$refs.fileRef.dispatchEvent(new MouseEvent('click'))
    },
        fileChange(e) {
        console.log(e)
        //获取流文件
        console.log(e.target.files)
        //获取文件名
        this.fileList.push(e.target.files[0])
        this.ruleForm.file=this.fileList[0]
        console.log(this.ruleForm.file,'kkkkkkkkkkkkkkkkkkkkk')


        // console.log(e.target.files[0].name)
        // console.log(this.ruleForm.file,'llllllllllllllll')
        },
        submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            addDrug(this.parmesdata).then((res)=>{
                console.log(res)
                this.ruleForm={
                name: '',
                dosageForm:'',
                approvalNumber:'',
                component:'',
                indications:'',
                functionalIndications:'',
                }
                this.parmesdata=null
                this.maskBoxIndex=false
                this.lodingFlag=true
                getDrugPageList(this.meidcparmes).then(res=>{
                this.tableData=res.data.data
                this.total=res.data.total*1
                this.currentPage=res.data.pageNum
                this.lodingFlag=false
        })
            })
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      fileUpload(event) {
      console.log(event);
      let file = event.target.files;//一个文件流
      let formData = new FormData();
      formData.append("file", file[0]);
      console.log(formData);

      formData.append( 'name', this.ruleForm.name);
      formData.append( 'dosageForm', this.ruleForm.dosageForm);
      formData.append( 'approvalNumber', this.ruleForm.approvalNumber);
      formData.append( 'component', this.ruleForm.component);
      formData.append( 'indications', this.ruleForm.indications);
      formData.append( 'functionalIndications', this.ruleForm.functionalIndications);
      this.parmesdata=formData
    
    },
        toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      handleSelectionChange(val) {
        console.log(val,'kkk')
        this.multipleSelection = val;
      },
      add(){
        this.maskBoxIndex=true
        console.log('新增药品')
      },
      bianji(){
        console.log('编辑药品')
        if(this.multipleSelection.length<=0){
            alert('请选择一个药品')
        }else if(this.multipleSelection.length>1){
            alert('只能一个一个修改奥')
        }else{
            this.maskBoxIndex=true
            console.log(this.multipleSelection[0])
            this.ruleForm.name=this.multipleSelection[0].name
            this.ruleForm.dosageForm=this.multipleSelection[0].dosageForm
            this.ruleForm.approvalNumber=this.multipleSelection[0].approvalNumber
            this.ruleForm.component=this.multipleSelection[0].component
            this.ruleForm.indications=this.multipleSelection[0].indications
            this.ruleForm.functionalIndications=this.multipleSelection[0].functionalIndications
        }
        // editDrug().then((res)=>{
        //     console.log(res)
        // })
      },
      deleteis(){
        console.log('删除药品')
        let arr=[]
        for(let i=0;i<this.multipleSelection.length;i++){
            arr.push(this.multipleSelection[i].id)
        }
        this.lodingFlag=true
        deleteDrug(arr).then((res)=>{
            console.log(res)
            getDrugPageList(this.meidcparmes).then(res=>{
            this.tableData=res.data.data
            this.total=res.data.total*1
            this.lodingFlag=false
        })
        })
      }
    },
    created () {
    },
    mounted(){
        this.lodingFlag=true
        getDrugPageList(this.meidcparmes).then(res=>{
            this.tableData=res.data.data
            this.total=res.data.total*1
            this.pageSize=res.data.pageSize
            this.currentPage=res.data.pageNum
            this.lodingFlag=false
        })
    },

}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  position: relative;
  .mask{
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    .mask_box{
        width: 60%;
        height: 60%;
        background: #fff;
        .el-form {
            margin-top: 30px;
            display: flex;
            flex-wrap: wrap;
    }
    .el-form-item{
        width: 300px;
    }
    .upload-demo{
        width: 300px;
    }
    }
  }
  .container_top{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    span{
        font-size: 30px;
        font-weight: 600;
        margin-left: 20px;
    }
  }
  .container_bottom{
    width: 100%;
    height: 90%;
  }
}
</style>